const users = [
    {
        firstname: 'Matthieu',
        url: 'https://cowboy.mention-me.com/m/ol/ow5fc-matthieu-renaut',
    },
    {
        firstname: 'Franck',
        url: 'https://cowboy.mention-me.com/m/ol/ng9qr-crp-jean-francky-franck',
    },
    {
        firstname: 'Vincent',
        url: 'https://cowboy.mention-me.com/m/ol/jo3sp-vincent-mezou',
    },
    {
        firstname: 'Flavien',
        url: 'https://cowboy.mention-me.com/m/ol/di7lu-flavien-sicard',
    },
    {
        firstname: 'Éric',
        url: 'https://cowboy.mention-me.com/m/ol/un4os-eric-attanasio',
    },
    {
        firstname: 'Adrien',
        url: 'https://cowboy.mention-me.com/m/ol/fq6ge-adrien-pho',
    },
];
const user = users[Math.floor(Math.random() * users.length)];
// const apiKey = '9549770b-ff5d-4b35-ae53-5aa0498e3932';
// const requestUrl = `https://opengraph.io/api/1.1/site/${encodeURIComponent(user.url)}?app_id=${apiKey}`;
const referral = document.querySelector('.referral') || null;

// <img src="${response.openGraph.image.url}" class="referral-img" alt="">
function displaReferral(response) {
    referral.insertAdjacentHTML(
        'afterbegin',
        `
            <a href="${user.url}" class="referral-link" target="_blank">
                <img src="assets/img/referral.jpg" class="referral-img" alt="">
                Obtiens une bequille <br>et un porte-bagages gratuits <br>grace a ${user.firstname} !
            </a>
        `,
    );
}

if (referral) {
    displaReferral();
    // fetch(requestUrl)
    //     .then(response => response.json())
    //     .then(data => {
    //         displaReferral(data);
    //     });
}
